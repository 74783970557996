import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { setTheme } from 'ngx-bootstrap/utils';
import { SpinnerService } from './core/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked, OnDestroy {
  isAuthenticated: boolean;
  spinnerVisibility: Observable<boolean>;
  userData: any;

  private _destroyUnsubscription: Subject<boolean> = new Subject();

  constructor(
    _spinnerService: SpinnerService,
    private _changeDetectorRef: ChangeDetectorRef,
    _translate: TranslateService,
    _swUpdate: SwUpdate
  ) {

    this.spinnerVisibility = _spinnerService.visibility;

    setTheme('bs4'); // or 'bs3'
    _translate.addLangs(['en', 'nl']);
    _translate.setDefaultLang(environment.preferredLanguage);

    _translate.reloadLang('en');
    _translate.reloadLang('nl');

    if (_swUpdate.isEnabled) {
      _swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          _swUpdate.activateUpdate().then(() => {
            document.location.reload();
          }).catch(error => {
            console.error('Error activating service worker:', error);
          });
        }
      });
    }

  }

  ngAfterViewChecked() {
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyUnsubscription.next();
    this._destroyUnsubscription.complete();
  }
}
