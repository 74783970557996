import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorStatusCode, HttpSuccessStatusCode } from '../../../shared/enums/http-request-status-code.enum';
import { ICustomResponseModel } from '../../models/custom-error-response.model';
import { ToasterComponent } from './toaster.component';
import { ISnackBarDataModel } from './toaster.model';

@Injectable()
export class ToasterService {

  private _snackBarRef: MatSnackBarRef<ToasterComponent>;
  snackBarConfig: MatSnackBarConfig;

  constructor(private _snackBar: MatSnackBar, private _translate: TranslateService) {
    // basic configuration for snackbar
    this.snackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 4000
    };
  }

  /**
   * To show toaster/ snackbar on scree when http request has succeeded
   * @param response: take http success response as 'ICustomResponseModel'
   */
  successMethod(response: ICustomResponseModel) {
    // add default color for snackbar
    this.snackBarConfig.panelClass = ['default-snackbar'];

    // It is default data which will get show on screen with snackbar
    this.snackBarConfig.data = <ISnackBarDataModel>{
      icon: 'gpr-checkmark',
      message: response.message
    };

    // To decide snackbar type as per http request response status code
    switch (response.statusCode) {
      case HttpSuccessStatusCode.OK: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.ACCEPTED: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.CREATED: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.NO_CONTENT: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
    }
    this.showToaster();
  }

  /**
   * To show toaster/ snackbar on scree when http request has failed
   * @param response: take http error response as 'ICustomResponseModel'
   */
  errorMethod(response: ICustomResponseModel) {
    // add default color for snackbar
    this.snackBarConfig.panelClass = ['default-snackbar'];

    // It is default data which will get show on screen with snackbar
    this.snackBarConfig.data = <ISnackBarDataModel>{
      icon: 'gpr-warning',
      message: response.message
    };

    // To decide snackbar type as per http request response status code
    switch (response.statusCode) {
      case HttpErrorStatusCode.NOT_FOUND: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.BAD_REQUEST: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.FORBIDDEN: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.PAYMENT_REQUIRED: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.METHOD_NOT_ALLOWED: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.NOT_ACCEPTABLE: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.REQUEST_TIMEOUT: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.CONFLICT: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.UNAUTHORIZED: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.UNSUPPORTED_MEDIA_TYPE: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.INTERNAL_SERVER_ERROR: {
        this.snackBarConfig.data.icon = 'gpr-close';
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
      case HttpErrorStatusCode.SERVICE_UNAVAILABLE: {
        this.snackBarConfig.data.icon = 'gpr-close';
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
      default: {
        this.snackBarConfig.data = <ISnackBarDataModel>{
          icon: 'gpr-info-closed',
          message: this._translate.instant('API_ERROR_MESSAGE.SERVER_IS_NOT_RUNNING')
        };
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
    }
    this.showToaster();
  }

  /**
   * To show toaster/ snackbar on screen
   */
  private showToaster() {
    this._snackBarRef = this._snackBar.openFromComponent(ToasterComponent, this.snackBarConfig);
  }
}
