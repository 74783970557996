<section class="main-section">
  <div class="main-section--bg">
    <!-- Header section -->
    <nav class="navbar">
      <!-- Toggle Menu-->
      <button data-test="hamburger-menu" [matMenuTriggerFor]="menu" mat-icon-button class="menu text-white header-button-margin">
        <i data-test="hamburger-menu-icon" class="gpr-hamburger-menu"></i>
      </button>

      <mat-menu #menu="matMenu" class="hamburger-menu">
        <a data-test="manage-my-data" [href]="identityProviderUrl + 'manage/manageaccount'" mat-menu-item>
          <i class="gpr-user pr-1"></i>
          {{ 'HEADER.MANAGE_MY_DATA' | translate }}
        </a>
        <a data-test="license" *ngIf="managerClaim$ | async" mat-menu-item routerLink="/dashboard/license">
          <i class="gpr-settings pr-1"></i>
          {{ 'HEADER.LICENSE_MANAGER' | translate }}
        </a>
        <a data-test="file-manager" [routerLink]="'/dashboard/calculations'" mat-menu-item>
          <i class="gpr-file-management pr-1"></i>
          {{ 'HEADER.FILE_MANAGER' | translate }}
        </a>
        <mat-divider></mat-divider>
        <a data-test="logout" (click)="logout()" mat-menu-item>
          <i class="gpr-logout pr-1"></i>
          {{ 'HEADER.LOGOUT' | translate}}
        </a>
      </mat-menu>

      <!-- Brand -->
      <span class="navbar-brand nav-item pt-2 mr-auto">
        <a data-test="gpr-logo-link" class="svglink header-button-margin" [routerLink]="'/dashboard'">
          <object type="image/svg+xml" class="logo">
            <strong data-test="gpr-logo" class="logo">GPR</strong>
          </object>
        </a>
      </span>
    </nav>

    <!-- Component load section -->
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>

  <!-- Footer Component -->
  <app-footer></app-footer>
</section>