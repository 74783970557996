import { Injectable, isDevMode } from '@angular/core';
import { AuthService } from './auth.service';
import { UpV1Service } from './UpV1Service.Generated';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private _authService: AuthService, private _upV1Service: UpV1Service) {
  }

  /**
   * Send errors to the server
   * @param message 
   * @param stack 
   */
  logError(message: string, stack: string) {
    const logData = this.generateLogDetails(stack);

    if (isDevMode()) {
      console.error(message, stack);
      return;
    }
    
    this.saveErrorLogDetails(logData);
  }

  /**
   * Create custom error log details
   * @param stack 
   * @returns string log info
   */
  generateLogDetails(stack: string): string {
    const loggedInUser = this.getUserInfo().name;
    const urlLocation = document.location.href;

    const logData = `User: ${loggedInUser}, Url: ${urlLocation}, Stacktrace: ${stack}`
    return logData;
  }

  /**
   * Get loggedIn user profile
   * @returns 
   */
  getUserInfo() {
    return this._authService.getUserInfo().profile;
  }

  /**
   * Saving the error log on server
   * @param message 
   */
  saveErrorLogDetails(message: string) {
    this._upV1Service.saveFrontEndLogs(message)
      .subscribe();
  }
}
