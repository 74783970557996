import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  identityProviderUrl: string = environment.identityProviderUrl;

  managerClaim$: Observable<boolean>;

  constructor(private _authService: AuthService) {
    this.managerClaim$ = _authService.getManagerClaims();
  }

  logout() {
    // flush out session storage when user logs out
    sessionStorage.removeItem('selectedIdpUsers');
    sessionStorage.removeItem('searchFilterKey');
    this._authService.logout();
  }
}
