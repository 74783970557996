import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core'

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {

  constructor(
    private _translate: TranslateService
  ) { super();  }

  itemsPerPageLabel = 'Items per pagina';
  nextPageLabel = this._translate.instant('PAGINATOR_CUSTOM_TOOLTIP.NEXT') ;
  previousPageLabel = this._translate.instant('PAGINATOR_CUSTOM_TOOLTIP.PREVIOUS');
  firstPageLabel = this._translate.instant('PAGINATOR_CUSTOM_TOOLTIP.FIRST_PAGE');
  lastPageLabel = this._translate.instant('PAGINATOR_CUSTOM_TOOLTIP.LAST_PAGE');

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 van ${length}`;
    }

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} van ${length}`;
  }
}
