<!-- GPR Spinner -->
<ng-container *ngIf="spinnerVisibility | async; then spinner"></ng-container>
<ng-template #spinner>
  <div data-test="spinner" class="spinner-overlay">
    <div class="spinner">
      <object type="image/svg+xml" data="../assets/img/gpr-spinner-blocks.svg">
        <img data-test="spinner-image" ngSrc="../assets/img/gpr-spinner-blocks.gif" alt="loader" width="220"
          height="220" priority>
      </object>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>