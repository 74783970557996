import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-compatibility',
  templateUrl: './app-compatibility.component.html',
  styleUrls: ['./app-compatibility.component.scss']
})
export class AppCompatibilityComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }



}
