export const environment = {
  production: false,
  gprMaterialUrl: 'https://stagingmateriaal.gprportaal.nl',
  UpV1BaseUrl: 'https://stagingapi.gprportaal.nl',
  uploadsBaseUrl: 'https://staginguploads.weappmanager.com/',
  clientId: 'userportal',
  clientRoot: 'https://staging.gprportaal.nl/',
  identityProviderUrl: 'https://staging.gprlogin.nl/',
  preferredLanguage: 'nl', // en or nl
  coursesFeedUrl: 'https://www.gprsoftware.nl/feed/?post_type=cursussen',
  matomoConfig: {
    scriptUrl: '//cdn.matomo.cloud/ngx.matomo.cloud/matomo.js',
    trackers: [
      {
        trackerUrl: 'https://analytics.w-e.nl/', // URL of the Matomo instance to connect to
        siteId: 10 // SiteId for this application/site
      }
    ],
    trackAppStart: true,
    routeTracking: {
      enable: true
    }
  }
};
