<div class="card text-center">
  <div class="card-header">
    Pas op!
  </div>
  <div class="card-body">
    <h5 class="card-title">U gebruikt een verouderde browser</h5>
    <p class="card-text">Helaas kunnen wij vanuit beveiliging en gebruikerservaring, verouderde browsers niet ondersteunen. <br />Wij raden u voor het gebruik van onze applicaties en uw veiligheid aan om een andere browser te gebruiken zoals Microsoft Edge, Google Chrome of Mozilla FireFox</p>
    <p class="card-text">Op een computer met Microsoft Windows versie 8 of hoger, is Edge meestal al beschikbaar. Om de overige browsers te downloaden kunt u op onderstaande knoppen klikken.</p>
    <p class="card-text">Neem gerust contact met ons op via <a href="https://gprlogin.nl/home/contact">dit formulier</a> als u nog vragen heeft.</p>
    <a href="https://www.google.nl/chrome/index.html" class="btn btn-primary">Google Chrome</a>&nbsp;
    <a href="https://www.mozilla.org/nl/" class="btn btn-primary">FireFox</a>
  </div>
  <div class="card-footer text-muted">

  </div>
</div>
