import {NgModule} from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {ToasterComponent} from './toaster.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ToasterComponent],
  imports: [MatSnackBarModule, CommonModule],
  //entryComponents: [ToasterComponent], don't need them with Ivy
  exports: [ToasterComponent]
})
export class CustomToasterModule {
}
