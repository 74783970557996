import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { Observable, from, throwError } from "rxjs";
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, retry } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { UpV1Service } from "./UpV1Service.Generated";
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //private _clockService: ClockService;
  private _userManager: UserManager = new UserManager(getClientSettings());
  private _user: User;

  constructor(
    private _upV1Service: UpV1Service,
    private _appService: AppService
  ) {
    this._userManager.getUser().then(user => {
      this._user = user;
    });

    this._userManager.events.addAccessTokenExpiring(() => {
      console.warn('AccessTokenExpiring');
      this.signinSilent();
    });

    localStorage.setItem('requestedUrl', window.location.href);
  }

  /**
   * Get loggedIn user id
   */
  getLoggedInUserId(): Observable<any> {
    const loggedInUserId = this._appService.getLoggedInUserId();
    if (loggedInUserId) {
      return of(true);
    }

    return this._upV1Service.getLoggedInUserId()
      .pipe(
        retry(5),
        map((id) => {
          this._appService.setLoggedInUserId(id);
          return true
        }),
        catchError(() => throwError(false))
      );
  }

  /**
   * To trigger a silent request (via an iframe) to the authorization endpoint.
   * Need 'silent_redirect_uri' configuration
   * it returns user object
   */
  signinSilent() {
    this._userManager.signinSilent().then((user) => {
      this._user = user;
    })
      .catch((error) => {
        console.error(error);
        this.clearState();
        this.login();
      });
  }

  /**
   * Redirect ot IDP login page
   * @returns 
   */
  login(): Promise<any> {
    return this._userManager.signinRedirect();
  }

  /**
   * User logout and redirect to IDP login page 
   * @returns
   */
  logout(): Promise<any> {
    return this._userManager.signoutRedirect();
  }

  /**
   * Checking user has logged or not
   * @returns boolean
   */
  isLoggedIn(): Observable<boolean> {
    return of(this._user && this._user.access_token && !this._user.expired);
  }

  /**
  * Checking app has been authorized or not
  * @returns observable boolean
  */
  isLoggedInObs(): Observable<boolean> {
    return from(this._userManager.getUser())
      .pipe(
        map((user) => {
          if (user && !user.expired) {
            this._user = user;
            return true;
          }
          return false;
        })
      );
  }

  /**
  * Remove from any storage the currently authenticated user
  */
  removeAuthenticatedUser() {
    return this._userManager.removeUser();
  }

  /**
   * Returns loggedIn user access token
   * @returns string or null
   */
  getAccessToken(): string {
    return this._user ? this._user.access_token : null;
  }

  /**
  * Removes stale state entries in storage for incomplete authorize requests
  */
  clearState() {
    this._userManager.clearStaleState().then(() => {
      console.log('clearStateState success');
    }).catch((e) => {
      console.warn(e.message);
    });
  }

  /**
  * To get the auth context
  */
  getManagerClaims(): Observable<boolean> {
    return this._upV1Service.authContext()
      .pipe(map(resp => resp.claims.findIndex(claim => claim.type == 'licenseManagerIds') != -1));
  }

  //getClientTime(): Promise<number> {
  //  return this._clockService.getEpochTime();
  //}

  getUserInfo() {
    return this._user;
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: environment.identityProviderUrl,
    client_id: environment.clientId,
    redirect_uri: `${environment.clientRoot}assets/oidc-login-redirect.html`,
    scope: 'openid profile applicationList userportalapi',
    response_type: 'id_token token',
    post_logout_redirect_uri: `${environment.clientRoot}logout?client_id=${environment.clientId}`,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    silent_redirect_uri: `${environment.clientRoot}assets/silent-redirect.html`,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    clockSkew: 500,
    //clockService: this._clockService
  };
}
