import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LoggingService } from './core/services/logging.service';

@Injectable()
export class GlobalErrorHandlerService {

  constructor(private injector: Injector) { }

  /**
   * Detects error on application
   * @param error 
   */
  handleError(error: any) {

    if (error.message.match('Expression has changed after it was checked')) {
      return;
    }

    const logger = this.injector.get(LoggingService);

    let message;
    let stackTrace;
    if (error instanceof HttpErrorResponse) {
      message = this.getServerErrorMessage(error);

    } else {
      // Client Error
      message = this.getClientErrorMessage(error);
    }

    // Always log errors
    logger.logError(message, stackTrace || error.stack);

  }

  /**
   * Returns client related error message
   * @param error 
   * @returns 
   */
  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  /**
   * Returns network related message
   * @param error 
   * @returns 
   */
  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ? error.message : 'No Internet Connection';
  }
}
