import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApplicationDto } from './UpV1Service.Generated';

@Injectable()
export class AppService {

  private _loggedInUserId: number;
  private _materialApplication: ApplicationDto;

  constructor(private _http: HttpClient) { }

  getCoursesRSS(): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/xml');

    return this._http.get(
      environment.coursesFeedUrl,
      {
        headers: new HttpHeaders(),
        responseType: 'text'
      }
    );
  }

  /**
   * Set id of loggedIn user
   * @param userId: current loggedIn user id
   */
  setLoggedInUserId(userId: number) {
    this._loggedInUserId = userId;
  }

  /**
   * Return the loggedIn user id
   */
  getLoggedInUserId = (): number => this._loggedInUserId;

  getMaterialLicentiebeheerAppId = (): number => this._materialApplication ? this._materialApplication.gprLicentiebeheerAppId : null;

  getMaterialApplicationId = (): number => this._materialApplication ? this._materialApplication.id : null;

  getMaterialApplicationContentVersionId =
    (): number => this._materialApplication ? this._materialApplication.contentVersions[this._materialApplication.contentVersions.length - 1].id : null;

  /**
 * Sets the material application.
 * @param {ApplicationDto} application - The application to set.
 */
  setMaterialApplication(application: ApplicationDto) {
    this._materialApplication = application;
  }

}
