import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ISnackBarDataModel } from './toaster.model';

@Component({
  selector: 'app-snack-bar',
  template: `
    <span data-test="toaster-message">
      <i class="pr-1" data-test="toaster-icon" [ngClass]="data.icon"></i> {{ data.message }}
    </span>
  `,
})
export class ToasterComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarDataModel) {
  }

}
