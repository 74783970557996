<section class="page-center">

  <div class="row text-center">
    <div class="col-md-12">
      <figure>
        <img data-test="error-image" src="assets/img/error404.svg" alt="error image" />
      </figure>
    </div>
    <div class="col-md-12">
      <h1 data-test="error-title">Oops!!</h1>
      <h3 data-test="error-message">{{ 'NOT_FOUND.THIS_PAGE_NOT_EXIST' | translate}}</h3>
    </div>
  </div>

  <a data-test="back-to-home-link" class="nav-link" [routerLink]="['/dashboard']">
    {{ 'NOT_FOUND.BACK_TO_HOME' | translate}}
  </a>

</section>