<footer data-test="footer">
  <nav data-test="footer-nav">
    <a data-test="contact" href="{{identityProviderUrl}}home/contact" target="_blank">
      {{ 'FOOTER.CONTACT' | translate}}
    </a>
    <a data-test="about" href="https://www.w-e.nl/over-w-e-adviseurs/ " target="_blank">
      {{ 'FOOTER.ABOUT_THE_CREATOR' | translate}}
    </a>
    <a data-test="privacy" href="{{identityProviderUrl}}home/privacy" target="_blank">
      {{ 'FOOTER.PRIVACY' | translate}}
    </a>
    <a data-test="termAndConditions" href="{{identityProviderUrl}}documents/Algemene_voorwaarden_GPR_Software.pdf"
       target="_blank">
      {{ 'FOOTER.TERMS_AND_CONDITIONS' | translate}}
    </a>
  </nav>
</footer>
