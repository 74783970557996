import { Injectable, isDevMode } from '@angular/core';


/**
 * wrapping this angular isDevMode() method into a service
 */
 @Injectable({ providedIn: 'root' })
 export class DevModeService {
   constructor() {}
 
   isDevMode() {
     return isDevMode();
   }
 }
 

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService  {

  constructor(private _devModeService: DevModeService){}

  canLoad(): boolean {
    if (!this._devModeService.isDevMode() && location.protocol !== 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      return false;
    }
    return true;
  }

  canActivate(): boolean {
    if (!this._devModeService.isDevMode() && location.protocol !== 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      return false;
    }
    return true;
  }

}


