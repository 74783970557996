import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {ToasterService} from './services/toaster/toaster.service';
import {CustomToasterModule} from './services/toaster/toaster.module';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from '../shared/material-module';
import {TranslateModule} from '@ngx-translate/core';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";


@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CustomToasterModule,
    BsDropdownModule.forRoot(),
    MaterialModule,
    TranslateModule
  ],
  providers: [ToasterService],
  exports: [HeaderComponent]
})
export class CoreModule {
}
