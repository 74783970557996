import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, Router  } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private _authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.hasTitleProperty(route)) {
      // Handle the case where ActivatedRouteSnapshot does not have a title property
      console.error('Route does not have a title property:', route);
      this.router.navigate(['/']); // Redirect to home or any other default route
      return false;
    }
    return this._authService.isLoggedIn();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this._authService.isLoggedIn();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this._authService.isLoggedIn();
  }

   /**
   * has Title Property
   * @param route
   * @returns
   */
   private hasTitleProperty(route: ActivatedRouteSnapshot): boolean {
    return !!route.data && !!route.data.title;
  }
}
