import { NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_ID, ApplicationRef, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { CustomToasterModule } from './core/services/toaster/toaster.module';

import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMatomoModule } from 'ngx-matomo-client';
import { environment } from '../environments/environment';
import { AppCompatibilityComponent } from './app-compatibility/app-compatibility.component';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { AppService } from './core/services/app.service';
import { AuthService } from './core/services/auth.service';
import { InterceptorService } from './core/services/interceptor.service';
import { UpV1_BASE_URL, UpV1Service } from './core/services/UpV1Service.Generated';
import { SpinnerService } from './core/spinner.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';

registerLocaleData(localeNL);

// AoT requires an exported function for factories

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    AppCompatibilityComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }), 
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }), 
    AppRoutingModule,
    CustomToasterModule,
    CoreModule,
    HttpClientModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMatomoModule.forRoot(environment.matomoConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    SpinnerService,
    UpV1Service,
    AppService,
    {
      provide: UpV1_BASE_URL,
      useValue: environment.UpV1BaseUrl
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl-NL'
    },
    { provide: APP_ID, useValue: 'ng-cli-universal' }
  ]
})
export class AppModule {
  constructor(private authService: AuthService) {
  }

  ngDoBootstrap(app: ApplicationRef) {
    const componentSelector = checkBrowserCompatibility();

    if ('app-root' === componentSelector) {
      this.authService.isLoggedInObs().subscribe(resp => {
        if (!resp) {
          this.authService.login();
          return;
        }

        bootstrapRootComponent(app, componentSelector);
      },
        () => this.authService.login());
    }
    else {
      bootstrapRootComponent(app, componentSelector);
    }
  }
}

function checkBrowserCompatibility(): string {
  const userAgent = window.navigator.userAgent;

  // Chrome "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.149 Safari/537.36"
  const isChrome = (userAgent.indexOf('Chrome/')) > -1;

  // Firefox "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:75.0) Gecko/20100101 Firefox/75.0"
  const isFirefox = (userAgent.indexOf('Firefox/')) > -1;

  // Safari  "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1 Safari/605.1.15"
  const isSafari = (userAgent.indexOf('Safari/')) > -1;

  // Internet Explorer "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"
  const isIE = (userAgent.indexOf('Trident/')) > -1;

  // Edge "Mozilla/5.0 (Windows NT 10.0; Win64; x64; ServiceUI 14) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.18362"
  const isEdge = (userAgent.indexOf('Edge/')) > -1;

  const rootElement = document.getElementById('root-element');

  if (isChrome || isEdge || isFirefox || isSafari) {
    return 'app-root';
  }
  return 'app-compatibility';
}

function bootstrapRootComponent(applicationRef: ApplicationRef, selector: string) {
  const components = {
    'app-compatibility': AppCompatibilityComponent,
    'app-root': AppComponent
  };

  // create DOM element for the component being bootstrapped
  const componentElement = document.createElement(selector);
  document.body.appendChild(componentElement);

  // bootstrap the application with the selected component
  const component = components[selector];
  applicationRef.bootstrap(component);

  const appSpinner = document.querySelector('.app-loading');
  appSpinner.parentElement.removeChild(appSpinner);
}
